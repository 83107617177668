import { CampaignTypeEnum, MessageTypeEnum } from '@gr/shared/enums';
import { ICalculateLeadsRequest, IContactListEntity, IHttpResponse } from '@gr/shared/models';
import { Button, ButtonVariantEnum, CalculateIcon } from '@Wonder-Cave/ui';
import { useState } from 'react';
import { axiosPost } from '../../authAxios';
import { IDropdownValue } from '../shared/Form/Dropdown';
import NewCampaignTypeDropdown from '../shared/Form/Dropdowns/NewCampaignTypeDropdown';
import ClientDropdown from '../shared/Form/Dropdowns/NewClientDropdown';
import NewMessageTypeDropdown from '../shared/Form/Dropdowns/NewMessageType';

interface IContactListLeadGenerationProps {
  selectedItem: IContactListEntity | undefined;
  totalContactCount: number;
}

const ContactListLeadGeneration = ({ selectedItem, totalContactCount }: IContactListLeadGenerationProps) => {
  const [showLeadsCount, setShowLeadsCount] = useState(false);
  const [leadsCount, setLeadsCount] = useState(0);
  const [calculateLoading, setCalculateLoading] = useState(false);

  const [selectedClient, setSelectedClient] = useState<IDropdownValue>();
  const [selectedCampaignType, setSelectedCampaignType] = useState<CampaignTypeEnum>();
  const [selectedMessageType, setSelectedMessageType] = useState<IDropdownValue>();
  const [collapseLeadGeneration, setCollapseLeadGeneration] = useState(false);

  const calculateLeads = async () => {
    setCalculateLoading(true);
    try {
      const request: ICalculateLeadsRequest = {
        contactListIds: [selectedItem!.id],
        clientId: selectedClient?.value,
        campaignType: selectedCampaignType!,
        campaignMessageType: selectedMessageType?.value!,
      };

      const {
        data: { data },
      } = await axiosPost<IHttpResponse<number>>('/contact-lists-calculate-leads', request);

      setLeadsCount(data);
    } catch (err) {
      console.error(err);
    } finally {
      setCalculateLoading(false);
      setShowLeadsCount(true);
    }
  };

  const handleClientSelected = (newValue: IDropdownValue | undefined) => {
    setSelectedClient(newValue);
    setSelectedCampaignType(undefined);
    setSelectedMessageType(undefined);
    setLeadsCount(0);
    setShowLeadsCount(false);
  };

  return (
    <><div className="mt-6">
      <div
        className=""
      >
        <h3
          className='text-lg font-extrabold leading-6 text-black'>
          Lead Generation Calculator
        </h3>
        <p className="max-w-2xl mt-1 text-sm text-gray-500">
          Determine the number of leads for a given client and campaign type
        </p>
      </div>


      <div className="flex flex-wrap w-full mt-4 bg-slate-200 shadow dark:bg-slate-700 dark:border-2 dark:border-slate-800 sm:rounded-lg space-x-10 h-64 pt-6">
        <div className="w-1/3 ps-20 ">
          <ClientDropdown
            value={selectedClient}
            placeholder='Search for a client...'
            onChange={(newValue) => {
              handleClientSelected(newValue as IDropdownValue);
            }} />

        </div>
        <div className="w-1/6">
          <NewMessageTypeDropdown
            value={selectedMessageType?.value}
            onChange={(newValue) => {
              return setSelectedMessageType(newValue);
            }}
            options={[
              { label: 'SMS', value: MessageTypeEnum.SMS },
              { label: 'MMS', value: MessageTypeEnum.MMS }
            ]} showError={false} />
        </div>
        <div className="w-1/3 pr-20">
          <NewCampaignTypeDropdown
            placeholder='Select'
            value={selectedCampaignType}
            clientId={selectedClient?.value ?? ''}
            messageType={selectedMessageType?.value}
            onChange={(newValue: IDropdownValue | undefined) => {
              setSelectedCampaignType(newValue?.value);
            }}
          />
        </div>
        <div className="ps-10">
          <Button
            type="button"
            className='mr-4 mb-10'
            onClick={calculateLeads}
            variant={ButtonVariantEnum.GREEN}
            disabled={!selectedClient || !selectedCampaignType || !selectedMessageType || !selectedItem}
            trailingIcon={
              <div className={calculateLoading ? 'spin' : ''}>
                <CalculateIcon />
              </div>
            }
            text="Calculate Leads" />
          {!calculateLoading && showLeadsCount && (
            <span className="inline-flex items-center ml-4">{leadsCount.toLocaleString()} leads</span>
          )}
        </div>
      </div>



    </div></>
  );
};

export default ContactListLeadGeneration;
