import { Button, ButtonVariantEnum, Modal } from '@Wonder-Cave/ui';

interface IContactListConfirmationModalProps {
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onCancel: () => void;
  action?: 'delete';
}

const ContactListConfirmationModal = ({ show, onClose, onSuccess, onCancel, action }: IContactListConfirmationModalProps) => {
  // leveraging object below to determine body of confirmation modal based upon action
  const body = {
    'delete': <>Once you delete a contact list, it will no longer be visible on the grid. <br />
      This action cannot be undone.'</>
  };
  // implementing switch to determine body of confirmation modal
  return (
    <Modal title="Are you sure?" show={show} onClose={onClose}>
      <h3 className="px-8 text-center">
        {body[action!]}
      </h3>
      <div className="flex justify-center mt-8 mb-4">
        <Button variant={ButtonVariantEnum.SECONDARY} onClick={onCancel} className="mr-4 uppercase">
          CANCEL
        </Button>
        <Button onClick={onSuccess} variant={ButtonVariantEnum.PRIMARY} className="uppercase">
          {`${action?.toUpperCase()} Contact List`}
        </Button>
      </div>
    </Modal>
  );
};

export default ContactListConfirmationModal;