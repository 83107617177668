import { NotificationType, useNotifications } from '@gr/portal/contexts/NotificationContext';
import { downloadFileFromUrl } from '@gr/portal/providers/utility.provider';
import {
  ExternalV1ContactListsDeleteRequest,
  IContactListEntity,
  IContactListUploadRequest,
  IContactListUploadResponse,
  ICreateContactListRequest,
  IExportContactListRequest,
  IGetContactListBreakdownResponse,
  IHttpResponse
} from '@gr/shared/models';
import { Button, ButtonVariantEnum, Static } from '@Wonder-Cave/ui';
import axios from 'axios';
import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { axiosGet, axiosPost } from '../../authAxios';
import { IButtonOptions } from '../shared/DetailsPanel/types';
import { IRadioButtonOption } from '../shared/Form/RadioButton';
import { NewTable } from '../shared/Table/NewTable';
import ContactListForm, { IContactListForm } from './ContactListForm';
import ContactListLeadGeneration from './ContactListLeadGeneration';
import { breakdownColumns, timezoneColumns } from './types';

interface IContactListDetailsProps {
  show: boolean;
  loading: boolean;
  errorMessage?: string;
  selectedItem?: IContactListEntity;
  leftPanelButtonOptions: IButtonOptions[];
  onClosePanel: () => void;
  handleSubmit: (formData: IContactListForm) => Promise<void>;
}

const ContactListDetails = ({
  show,
  loading,
  errorMessage,
  leftPanelButtonOptions,
  onClosePanel,
  handleSubmit,
}: IContactListDetailsProps) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const { addNotification } = useNotifications();
  const params: any = useParams();
  const [selectedItem, setSelectedItem] = useState<IContactListEntity>();
  const [collapseBreakdown, setCollapseBreakdown] = useState(false);
  const [collapseTimezone, setCollapseTimezone] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [prevStillUploading, setPrevStillUploading] = useState(false);
  const options: IRadioButtonOption[] = [
    {
      label: 'Enabled',
      value: true,
    },
    {
      label: 'Disabled',
      value: false,
    },
  ];


  const [{ data: breakdownData, loading: breakdownLoading }, getBreakdownData] = useAxios<
    IHttpResponse<IGetContactListBreakdownResponse[]>
  >(
    {
      url: '/contact-lists-get-breakdown',
      method: 'POST',
      data: { contactListId: params.contactListId },
    },
    { manual: true }
  );

  const [{ data: timezoneData, loading: timezoneLoading }, getTimezoneBreakdown] = useAxios<
    IHttpResponse<{ timezones: any[]; }>
  >(
    {
      url: '/contact-lists-get-timezones',
      method: 'POST',
      data: { contactListId: params.contactListId },
    },
    { manual: true }
  );

  const breakdownInfo = breakdownData?.data.map((item) => ({ type: item.type ?? 'Unknown', count: item.count })) ?? [];
  const totalContactCount = breakdownInfo?.reduce((sum, br) => sum + br.count, 0) ?? 0;

  const timezoneBreakdown = timezoneData?.data.timezones ?? [];

  const populateBreakdown = async () => {
    try {
      await Promise.all([getTimezoneBreakdown(), getBreakdownData()]);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (params.contactListId) {
      retrieveLoadingStatus();
      setCollapseBreakdown(false);
      setCollapseTimezone(false);
      populateBreakdown();
    }
  }, [params.contactListId]);

  const retrieveLoadingStatus = async () => {
    const currList = (await axiosGet(`/v1/contact-lists/${params.contactListId}`)).data.data;
    setSelectedItem(currList);
    setPrevStillUploading(currList?.isUploading);
  };

  const handleDelete = async () => {
    if (selectedItem?.id) {
      setIsDeleting(true);
      const deleteRequest: ExternalV1ContactListsDeleteRequest = {
        contactListId: selectedItem?.id,
      };
      await axiosPost('/v1/contact-list-delete', deleteRequest);

      addNotification({ header: 'Contact list deleted successfully' });
      history.back();
    }

  };
  const exportContactList = async () => {
    if (selectedItem?.id && selectedItem?.name) {
      try {
        const request: IExportContactListRequest = {
          contactListId: selectedItem?.id,
          contactListName: selectedItem?.name
        };
        setIsExporting(true);
        const {
          data: { data: signedUrl },
        } = await axiosPost<IHttpResponse<string>>('/contact-lists-export', request);

        downloadFileFromUrl(signedUrl);
      } catch (e) {
        console.error(e);
        addNotification({
          header: 'Error',
          content: 'Contact list failed to be exported.',
          type: NotificationType.FAILURE,
        });
      }
      finally {
        setIsExporting(false);
      }
    }
  };
  const updateContact = async (formData: IContactListForm) => {

    try {
      await updateContactList(formData);
      addNotification({
        type: NotificationType.SUCCESS,
        header: 'Contact list saved successfully!',
        content: formData.contactList
          ? `You will receive an email once the contacts have been added to the list.`
          : undefined,
      });
    } catch (error: any) {
      console.error(error);
      const data = error?.response?.data;
      const statusCode = data?.statusCode ?? error.statusCode;

      if (statusCode === 404) {
        addNotification({
          type: NotificationType.FAILURE,
          header: 'Failed to save Contact list',
          content: `We were unable to find the contact list`,
        });
      } else if (statusCode === 422) {
        addNotification({
          type: NotificationType.FAILURE,
          header: 'Failed to save Contact list',
          content: error.message,
        });
      } else {
        addNotification({
          type: NotificationType.FAILURE,
          header: 'Failed to save Contact list',
          content: `We were unable to save the contact list`,
        });
      }
    } finally {
      setSubmitLoading(false);
    }
  };
  const updateContactList = async (formData: IContactListForm): Promise<void> => {
    setSubmitLoading(true);
    await updateList({
      data: {
        id: selectedItem!.id,
        name: formData.name,
        isActive: formData.isActive!.value,
        isClassified: !formData.contactList,
        originalFileName: formData.contactList?.name,
      } as ICreateContactListRequest,
    });

    if (formData.contactList) {
      await uploadCsv(formData.name, formData);
    }
  };

  const uploadCsv = async (contactListName: string, formData: IContactListForm) => {
    const request: IContactListUploadRequest = {
      contactListName: contactListName,
    };

    const {
      data: {
        data: { url },
      },
    } = await axiosPost<IHttpResponse<IContactListUploadResponse>>('/contact-lists', request);

    await axios.put(url, formData.contactList, {
      headers: { 'Content-Type': 'text/csv' },
    });
  };

  const [{ error: updateError }, updateList] = useAxios<IHttpResponse<string>>(
    {
      url: '/contact-lists-update',
      method: 'POST',
    },
    { manual: true }
  );


  const onBack = () => {
    history.back();
  };

  return (
    <>
      <div className='px-40'>
        {prevStillUploading && (
          <span className="mb-6 text-red-700">
            We are still processing the previous upload. Please hold-off any updates for a few minutes.
          </span>
        )}
        {/* {!prevStillUploading && !selectedItem && (
          <LoadingIndicator />)} */}
        {!prevStillUploading && (

          <>
            <Static shimmer={10} title="">
              {!prevStillUploading && selectedItem && (
                <ContactListForm item={selectedItem} breakdownInfo={breakdownInfo} onSubmit={updateContact} />
              )}
            </Static>
            <div className="flex justify-right absolute top-0 right-0 pt-10 pr-48">
              <Button type="button" disabled={isDeleting} variant={ButtonVariantEnum.DELETE} onClick={handleDelete} className="mr-4 uppercase">
                DELETE CONTACT LIST
              </Button>
              <Button type="button" variant={ButtonVariantEnum.TERTIARY} onClick={exportContactList} className="uppercase">
                EXPORT
              </Button>
            </div>
            <div className="border-t-[3px] mt-15"> </div>
            <div className="mt-2 space-y-15 flex">
              <hr className='mt-10 bg-gray-700 '></hr>
              {/* TODO: Make this into a custom component */}

              <div className='w-1/2 mr-20'>
                <div>
                  <h3 className='text-lg leading-6 font-extrabold'>
                    Contact List Breakdown
                  </h3>
                  <p className="max-w-2xl mt-1 text-sm text-gray-500">
                    {totalContactCount === 0 ? '0 contacts' : `${totalContactCount.toLocaleString()} total contacts`}
                  </p>
                </div>
                <div className="">
                  {totalContactCount !== 0 && (
                    <NewTable columns={breakdownColumns} items={breakdownInfo} shimmer={true} height={'20vh'} />
                  )}
                </div>
              </div>
              <div className='w-1/2'>
                <div>
                  <h3 className='text-lg leading-6 font-extrabold'>
                    Timezone Breakdown
                  </h3>
                  <p className="max-w-2xl mt-1 text-sm text-gray-500">
                    {!breakdownLoading && totalContactCount === 0
                      ? '0 timezones'
                      : `${timezoneBreakdown?.length} timezones`}
                  </p>
                </div>
                <div className="">
                  {totalContactCount !== 0 && (
                    <NewTable columns={timezoneColumns} items={timezoneBreakdown} shimmer={false} height={'20vh'} />
                  )}
                </div>
              </div>

            </div>
            <div className="border-t-[3px] mt-15"> </div>
            <Static shimmer={10} title="">
              {!prevStillUploading && selectedItem && (
                <ContactListLeadGeneration selectedItem={selectedItem} totalContactCount={totalContactCount} />
              )}
            </Static>
            <div className="flex float-right pt-10 pb-20">
              <Button type="button" disabled={isDeleting} variant={ButtonVariantEnum.SECONDARY} onClick={onBack} className="mr-4 uppercase">
                CANCEL
              </Button>
              <button className='button-1' disabled={submitLoading} form="contact-form" type="submit" >SUBMIT</button>

            </div>
          </>
        )}
      </div >
    </>
  );
};

export default ContactListDetails;
