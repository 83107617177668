import { Button, ButtonVariantEnum, TableList, TextInput } from '@Wonder-Cave/ui';
import { downloadFileFromUrl } from '@gr/portal/providers/utility.provider';
import {
  ExternalV1ContactList,
  ExternalV1ContactListsDeleteRequest,
  FilterDataTypeEnum,
  FilterOperatorEnum,
  IContactListEntity,
  IContactListGetStatusRequest,
  IContactListUploadRequest,
  IContactListUploadResponse,
  ICreateContactListRequest,
  IExportContactListRequest,
  IHttpResponse
} from '@gr/shared/models';
import axios from 'axios';
import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { axiosPost } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import useContactLists from '../../hooks/useContactLists';
import ContactListClassificationProgressBar from './ContactListClassificationProgressBar';
import ContactListConfirmationModal from './ContactListConfirmationModal';
import { IContactListForm } from './ContactListForm';
import { defaultContactListTableOptions, getColumns } from './types';

const ContactLists = () => {
  const [selectedItem, setSelectedItem] = useState<IContactListEntity>();
  const [actionItem, setActionItem] = useState<IContactListEntity>();
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [tableOptions, setTableOptions] = useState(defaultContactListTableOptions);
  const { addNotification } = useNotifications();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [action, setAction] = useState<'delete'>();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [contactLists, setContactLists] = useState<any[]>([]);
  const history = useHistory();
  const [{ data, loading, error }, refetch] = useContactLists(tableOptions);

  const [{ error: createError }, createList] = useAxios<IHttpResponse<string>>(
    {
      url: '/contact-lists-create',
      method: 'POST',
    },
    { manual: true }
  );

  const [{ error: updateError }, updateList] = useAxios<IHttpResponse<string>>(
    {
      url: '/contact-lists-update',
      method: 'POST',
    },
    { manual: true }
  );

  const getLatestContactListStatuses = async (fetchedContactLists: ExternalV1ContactList[]) => {
    try {
      if (!loading) {
        const statusesResponse = await axiosPost<IHttpResponse<any>>(`/contact-lists-get-status`, {
          contactListIds: fetchedContactLists.map((list) => list.id),
        } as IContactListGetStatusRequest);
        const updatedStatuses = statusesResponse.data?.data ?? [];
        const statusCache = {};
        updatedStatuses.map((status) => statusCache[status.id] = { status: status.status, totalContacts: status.totalContacts, totalContactsClassified: status.totalContactsClassified });
        const updatedLists = fetchedContactLists.map((list) => {
          const updatedList = {
            ...list,
            status: statusCache[list.id].status,
            totalContacts: statusCache[list.id].totalContacts,
            totalContactsClassified: statusCache[list.id].totalContactsClassified
          };
          return updatedList;
        });
        setContactLists(updatedLists);
      }
    } catch (e) {
      console.error(e);
    }
  };


  const totalCount = data?.page?.total;

  useEffect(() => {
    if (data?.data) {
      let fetchedContactLists = data?.data ?? [];
      if (!Array.isArray(fetchedContactLists)) {
        fetchedContactLists = [fetchedContactLists];
      }
      setContactLists(fetchedContactLists);
      getLatestContactListStatuses(fetchedContactLists);
    }
  }, [data]);

  useEffect(() => {
    if (!loading) {
      const interval = setInterval(() => {
        getLatestContactListStatuses(contactLists);
      }, 30000);

      return () => {
        clearInterval(interval);
      };
    }

  }, [contactLists, loading]);

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (err) { }
  };

  useEffect(() => {
    const error = createError?.response?.data.message || updateError?.response?.data.message;
    setErrorMessage(error);
  }, [createError, updateError]);

  useEffect(() => {
    handleRefetch();
  }, [tableOptions]);

  const openDetailsPanel = (item?: any) => {
    setSelectedItem(item ?? undefined);
    setShowDetailsPanel(true);
  };

  const closeDetailsPanel = () => {
    setShowDetailsPanel(false);
    setSelectedItem(undefined);
    setErrorMessage('');
  };

  const columns = getColumns({
    name: (item: any) => (
      <h3
        onClick={() => {
          history.push(`/app/contact-lists/${item.id}`);
        }
        }
        title={item.name}
        className="mt-2 mb-2 max-w-lg text-black break-words whitespace-normal cursor-pointer hover:underline hover:text-wc-blue"
      >
        {item.name}
      </h3>),
    status: (item: any) => (
      <ContactListClassificationProgressBar contactList={item} />
    )
  });

  const handleSearch = (overrideText?: string) => {
    let searchFilter = [
      {
        dataType: FilterDataTypeEnum.STRING,
        fieldName: 'name',
        operator: FilterOperatorEnum.CONTAINS,
        value: searchText,
      },
    ];

    if (overrideText !== null && overrideText !== undefined) {
      setSearchText(overrideText);
      searchFilter[0].value = overrideText;
    }

    const newSearchOptions = { ...defaultContactListTableOptions };

    newSearchOptions.filters = searchFilter;

    setTableOptions(newSearchOptions);
  };

  const handleSubmit = async (formData: IContactListForm) => {
    let refetch = true;

    setSubmitLoading(true);

    try {
      selectedItem ? await updateContactList(formData) : await createContactList(formData);

      addNotification({
        type: NotificationType.SUCCESS,
        header: 'Contact list saved successfully!',
        content: formData.contactList
          ? `You will receive an email once the contacts have been added to the list.`
          : undefined,
      });
    } catch (error: any) {
      console.error(error);
      const data = error?.response?.data;
      const statusCode = data?.statusCode ?? error.statusCode;

      if (statusCode === 404) {
        addNotification({
          type: NotificationType.FAILURE,
          header: 'Failed to save Contact list',
          content: `We were unable to find the contact list`,
        });
      } else if (statusCode === 422) {
        refetch = false;
        addNotification({
          type: NotificationType.FAILURE,
          header: 'Failed to save Contact list',
          content: error.message,
        });
      } else {
        refetch = false;
        addNotification({
          type: NotificationType.FAILURE,
          header: 'Failed to save Contact list',
          content: `We were unable to save the contact list`,
        });
      }
    } finally {
      setSubmitLoading(false);
      closeDetailsPanel();
      refetch && handleRefetch();
    }
  };

  const createContactList = async (formData: IContactListForm): Promise<void> => {
    await createList({
      data: {
        name: formData.name,
        isActive: formData.isActive!.value,
        originalFileName: formData.contactList?.name,
      } as ICreateContactListRequest,
    });

    if (formData.contactList) {
      uploadCsv(formData.name, formData);
    }
  };

  const updateContactList = async (formData: IContactListForm): Promise<void> => {
    await updateList({
      data: {
        id: selectedItem!.id,
        name: formData.name,
        isActive: formData.isActive!.value,
        isClassified: !formData.contactList,
        originalFileName: formData.contactList?.name,
      } as ICreateContactListRequest,
    });

    if (formData.contactList) {
      await uploadCsv(formData.name, formData);
    }
  };

  const uploadCsv = async (contactListName: string, formData: IContactListForm) => {
    const request: IContactListUploadRequest = {
      contactListName: contactListName,
    };

    const {
      data: {
        data: { url },
      },
    } = await axiosPost<IHttpResponse<IContactListUploadResponse>>('/contact-lists', request);

    await axios.put(url, formData.contactList, {
      headers: { 'Content-Type': 'text/csv' },
    });
  };

  // todo 3899: remove this handle delete as we are removing details panel in this ticket
  const handleDelete = async () => {
    const deleteRequest: ExternalV1ContactListsDeleteRequest = {
      contactListId: selectedItem!.id,
    };
    await axiosPost('/v1/contact-list-delete', deleteRequest);

    closeDetailsPanel();

    handleRefetch();

    addNotification({ header: 'Contact list deleted successfully' });
  };

  const contactListDelete = async () => {
    try {
      const deleteRequest: ExternalV1ContactListsDeleteRequest = {
        contactListId: actionItem!.id,
      };
      setShowConfirmationModal(true);
      await axiosPost('/v1/contact-list-delete', deleteRequest);

      handleRefetch();

      addNotification({ header: 'Contact list deleted successfully' });
    } catch (err) {
      console.error(err);
      addNotification({
        header: 'Error',
        content: 'Contact list failed to be deleted.',
        type: NotificationType.FAILURE,
      });
    } finally {
      setActionItem(undefined);
      setShowConfirmationModal(false);
    }
  };
  const handleContactListAction = async () => {
    switch (action) {
      case 'delete': {
        await contactListDelete();
        break;
      }
    }

  };

  const exportContactList = async (contactListId: string, contactListName: string) => {
    if (contactListId && contactListName) {
      try {
        const request: IExportContactListRequest = {
          contactListId: contactListId,
          contactListName: contactListName,
        };

        const {
          data: { data: signedUrl },
        } = await axiosPost<IHttpResponse<string>>('/contact-lists-export', request);

        downloadFileFromUrl(signedUrl);
      } catch (e) {
        console.error(e);
        addNotification({
          header: 'Error',
          content: 'Contact list failed to be exported.',
          type: NotificationType.FAILURE,
        });
      }
    }
  };

  return (
    <>
      <div className="flex items-center justify-between pb-2 mx-auto px-[7.5rem] min-w-[1151px]">
        <h1>Contact Lists</h1>



        <div className="flex">
          <TextInput disabled={loading}
            id="contactListNameSearch"
            name="contactListNameSearch"
            placeholder="Search"
            parentClass="w-80"
            value={searchText}
            onEnter={(e) => {
              setTableOptions((prevState) => {
                // remove old search text from prev state
                const filteredPrevStateFilters = prevState.filters.filter((filter) => filter.fieldName !== 'name');
                prevState.filters = filteredPrevStateFilters;
                return {
                  ...prevState,
                  filters: [...prevState.filters,
                  ... (searchText.length > 0 ? [
                    {
                      fieldName: 'name',
                      dataType: FilterDataTypeEnum.STRING,
                      operator: FilterOperatorEnum.CONTAINS,
                      value: searchText,
                    },
                  ] : [])],
                };
              });
            }}
            onChange={(e) => {
              if (
                e.target.value.length < 1 &&
                tableOptions.filters.find((x) => x.fieldName === 'name')?.value?.length > 0
              ) {
                setTableOptions((prevState) => {
                  const filteredPrevStateFilters = prevState.filters.filter((filter) => filter.fieldName !== 'name');
                  filteredPrevStateFilters.push({
                    fieldName: 'name',
                    dataType: FilterDataTypeEnum.STRING,
                    operator: FilterOperatorEnum.CONTAINS,
                    value: '',
                  });
                  prevState.filters = filteredPrevStateFilters;
                  return {
                    ...prevState,
                    filters: [
                      ...prevState.filters
                    ],
                  };
                });
              }
              setSearchText(e.target.value);
            }}
            search
          />
          <Button
            variant={ButtonVariantEnum.PRIMARY}
            className="self-end ml-4"
            onClick={() => {
              history.push('/app/contact-lists/add');
            }}
            disabled={loading}
          >
            ADD CONTACT LIST
          </Button>
        </div>
      </div>
      <TableList
        shimmer
        loading={loading}
        columns={columns}
        items={contactLists}
        totalCount={totalCount ?? 0}
        limit={25}
        onPaginate={(page) => {
          setTableOptions((prevState) => ({
            ...prevState,
            pagination: {
              ...prevState.pagination,
              skip: page * prevState.pagination.take,
            },
          }));
        }}
        paginate
        actions={[
          {
            label: 'Delete',
            action: async (item) => {
              setActionItem(item);
              setAction('delete');
              setShowConfirmationModal(true);
            },
          },
          {
            label: 'Export',
            action: async (item: ExternalV1ContactList) => {
              await exportContactList(item.id, item.name);
            },
          },
        ]}
      />

      <ContactListConfirmationModal
        show={showConfirmationModal}
        onClose={() => {
          setActionItem(undefined);
          setShowConfirmationModal(false);
        }}
        onSuccess={() => {
          handleContactListAction();
          setShowConfirmationModal(false);
        }}
        onCancel={() => {
          setActionItem(undefined);
          setShowConfirmationModal(false);
        }}
        action={action}
      />
    </>
  );
};

export default ContactLists;
